import React, { useEffect } from "react";
import useMedia from "use-media";
import * as $ from "jquery";

const vid_img = require("../../assets/img/chatbot/hr_chatbot/hr_features_1.png");
export function VideoElement() {
  useEffect(() => {
    $("body").append(
      '\n <div class="lightbox">\n <a href="#lightbox" class="lightbox-close lightbox-toggle"><span>X</span></a>\n <div class="lightbox-container">\n <div class="row">\n <div class="col-sm-12 lightbox-column">\n\n </div>\n </div>\n </div>\n </div>\n '
    );

    $(".lightbox-toggle").on("click", function (event) {
      event.preventDefault();
      $(".lightbox").fadeToggle("fast");
      var context = $(event.currentTarget).attr("data-lightbox-type");
      var content = $(event.currentTarget).attr("data-lightbox-content");
      if (context == "video") {
        $(".lightbox-column").append(
          `\n<div class="lightbox-video">\n<iframe src="${content}" frameborder="0" &autoplay=1 ></iframe>\n</div>\n`
        );
      } else if (context == "image") {
        $(".lightbox-column").append(
          `\n<img src="${content}" class="img-" frameborder="0" allowfullscreen>\n`
        );
      }
    });

    $(".lightbox-close").on("click", function (event) {
      event.preventDefault();
      $(".lightbox-column > *").remove();
    });
  }, []);

  return (
    <section className="video_prompt_section video_section_home_new">
      <div className="container">
        <div className="video_prompt">
          <div className="main-slider-left">
            <h3 className="font-section-sub-header">
              Workplace support automation with chatbots starts here
            </h3>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="video_show">
              <div className="nav-item main-download-broucher">
                <div className="video_append">
                  <a
                    href="#lightbox"
                    className="lightbox-toggle nav-link url_manipulation"
                    data-lightbox-type="video"
                    data-lightbox-content="https://www.youtube-nocookie.com/embed/YRhHicf-CQM?autoplay=1"
                  >
                    <div className="svg_bg">
                      <svg
                        id="Capa_1"
                        enableBackground="new 0 0 565.648 565.648"
                        height={512}
                        viewBox="0 0 565.648 565.648"
                        width={512}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m282.824 0c-155.947 0-282.824 126.877-282.824 282.824s126.877 282.824 282.824 282.824 282.824-126.877 282.824-282.824-126.877-282.824-282.824-282.824zm-70.706 424.233v-282.822l212.118 141.41z" />
                      </svg>
                    </div>
                    <div className="watch_video" style="padding-top: 5px; ">
                      Watch Video
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="comapny_name">
            <i>#Workativ</i>
          </div>
        </div>
      </div>
    </section>
  );
}

export function VideoElement2(props) {
  const { videoURL, imgURL, imgMob } = props;
  const isSmall = useMedia({ maxWidth: "520px" });
  useEffect(() => {
    $("body").append(
      '\n <div class="lightbox">\n <a href="#lightbox" class="lightbox-close lightbox-toggle"><span>X</span></a>\n <div class="lightbox-container">\n <div class="row">\n <div class="col-sm-12 lightbox-column">\n\n </div>\n </div>\n </div>\n </div>\n '
    );

    $(".lightbox-toggle").on("click", function (event) {
      event.preventDefault();
      $(".lightbox").fadeToggle("fast");
      var context = $(event.currentTarget).attr("data-lightbox-type");
      var content = $(event.currentTarget).attr("data-lightbox-content");
      if (context == "video") {
        $(".lightbox-column").append(
          `\n<div class="lightbox-video">\n<iframe src="${content}" frameborder="0" &autoplay=1 ></iframe>\n</div>\n`
        );
      } else if (context == "image") {
        $(".lightbox-column").append(
          `\n<img src="${content}" class="img-" frameborder="0" allowfullscreen>\n`
        );
      }
    });

    $(".lightbox-close").on("click", function (event) {
      event.preventDefault();
      $(".lightbox-column > *").remove();
    });
  }, []);

  return (
    <section className="video_prompt_section video_prompt_with_background">
      <div className="container">
        <div
          className="video_prompt2 video_sub_cont_upt"
          style={
            isSmall
              ? { backgroundImage: `url(${imgMob})` }
              : { backgroundImage: `url(${imgURL})` }
          }
        >
          <div className="col-lg-12 col-md-12 col-12">
            <div className="video_show2">
              <div className="nav-item main-download-broucher">
                <div className="video_append">
                  <a
                    href="#lightbox"
                    className="lightbox-toggle nav-link url_manipulation"
                    data-lightbox-type="video"
                    data-lightbox-content={videoURL}
                  >
                    <div className="svg_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 90 90"
                        xmlSpace="preserve"
                        enableBackground="new 0 0 90 90"
                        {...props}
                      >
                        <filter
                          filterUnits="userSpaceOnUse"
                          height={90.019}
                          id="a"
                          width={90.021}
                          x={0}
                          y={0}
                        >
                          <feOffset dy={3} />
                          <feGaussianBlur result="blur" stdDeviation={3} />
                          <feFlood floodOpacity={0.071} />
                          <feComposite in2="blur" operator="in" />
                          <feComposite in="SourceGraphic" />
                        </filter>
                        <g
                          transform="translate(-914.98 -3795.98) translate(-58.02 -3450.02) translate(973 7246)"
                          filter="url(#a)"
                        >
                          <path
                            d="M45 6C25.1 6 9 22.1 9 42s16.1 36 36 36 36-16.1 36-36S64.9 6 45 6zm17.3 37.3l-25.5 15c-.2.1-.5.2-.8.2-.8 0-1.5-.7-1.5-1.5V27c0-.8.7-1.5 1.5-1.5.3 0 .5.1.8.2l25.5 15c.7.4 1 1.3.5 2.1-.1.2-.3.4-.5.5z"
                            fill="#fff"
                          />
                        </g>
                        <path
                          d="M1035.3 7289.3l-25.5 15c-.2.1-.5.2-.8.2-.8 0-1.5-.7-1.5-1.5v-30c0-.8.7-1.5 1.5-1.5.3 0 .5.1.8.2l25.5 15c.7.4 1 1.3.5 2.1-.1.2-.3.4-.5.5z"
                          fill="#f15e4e"
                          transform="translate(-914.98 -3795.98) translate(-58.02 -3450.02)"
                        />
                      </svg>
                    </div>
                    <div
                      className="watch_video"
                      style="padding-top: 5px; "
                    ></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export function VideoBlog(props) {
  const { videoURL, imgURL } = props;
  useEffect(() => {
    $("body").append(
      '\n <div class="lightbox">\n <a href="#lightbox" class="lightbox-close lightbox-toggle"><span>X</span></a>\n <div class="lightbox-container">\n <div class="row">\n <div class="col-sm-12 lightbox-column">\n\n </div>\n </div>\n </div>\n </div>\n '
    );

    $(".lightbox-toggle").on("click", function (event) {
      event.preventDefault();
      $(".lightbox").fadeToggle("fast");
      var context = $(event.currentTarget).attr("data-lightbox-type");
      var content = $(event.currentTarget).attr("data-lightbox-content");
      if (context == "video") {
        $(".lightbox-column").append(
          `\n<div class="lightbox-video">\n<iframe src="${content}" frameborder="0" &autoplay=1 ></iframe>\n</div>\n`
        );
      } else if (context == "image") {
        $(".lightbox-column").append(
          `\n<img src="${content}" class="img-" frameborder="0" allowfullscreen>\n`
        );
      }
    });

    $(".lightbox-close").on("click", function (event) {
      event.preventDefault();
      $(".lightbox-column > *").remove();
    });
  }, []);

  return (
    <section className="video_prompt_section video_prompt_with_background">
      <div
        className="video_prompt2"
        style={{ backgroundImage: `url(${imgURL})` }}
      >
        <div className="col-lg-12 col-md-12 col-12">
          <div className="video_show2">
            <div className="nav-item main-download-broucher">
              <div className="video_append">
                <a
                  href="#lightbox"
                  className="lightbox-toggle nav-link url_manipulation"
                  data-lightbox-type="video"
                  data-lightbox-content={videoURL}
                >
                  <div className="svg_bg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 90 90"
                      xmlSpace="preserve"
                      enableBackground="new 0 0 90 90"
                      {...props}
                    >
                      <filter
                        filterUnits="userSpaceOnUse"
                        height={90.019}
                        id="a"
                        width={90.021}
                        x={0}
                        y={0}
                      >
                        <feOffset dy={3} />
                        <feGaussianBlur result="blur" stdDeviation={3} />
                        <feFlood floodOpacity={0.071} />
                        <feComposite in2="blur" operator="in" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                      <g
                        transform="translate(-914.98 -3795.98) translate(-58.02 -3450.02) translate(973 7246)"
                        filter="url(#a)"
                      >
                        <path
                          d="M45 6C25.1 6 9 22.1 9 42s16.1 36 36 36 36-16.1 36-36S64.9 6 45 6zm17.3 37.3l-25.5 15c-.2.1-.5.2-.8.2-.8 0-1.5-.7-1.5-1.5V27c0-.8.7-1.5 1.5-1.5.3 0 .5.1.8.2l25.5 15c.7.4 1 1.3.5 2.1-.1.2-.3.4-.5.5z"
                          fill="#fff"
                        />
                      </g>
                      <path
                        d="M1035.3 7289.3l-25.5 15c-.2.1-.5.2-.8.2-.8 0-1.5-.7-1.5-1.5v-30c0-.8.7-1.5 1.5-1.5.3 0 .5.1.8.2l25.5 15c.7.4 1 1.3.5 2.1-.1.2-.3.4-.5.5z"
                        fill="#f15e4e"
                        transform="translate(-914.98 -3795.98) translate(-58.02 -3450.02)"
                      />
                    </svg>
                  </div>
                  <div className="watch_video" style="padding-top: 5px; "></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
